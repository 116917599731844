<!--
 * @Description: 选择岗位角色
 * @Author: 琢磨先生
 * @Date: 2022-09-17 12:07:41
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-18 08:28:32
-->
<template>
  <el-button type="primary" link icon="Plus" @click="onOpen">添加角色</el-button>
  <el-dialog
    title="选择岗位角色"
    v-model="visibleDialog"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
  >
    <el-table
      ref="table"
      :data="post_list"
      row-key="id"
      height="450px"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" width="80" reserve-selection></el-table-column>
      <el-table-column label="名称" prop="name" width="200"></el-table-column>
      <el-table-column label="描述" prop="description"></el-table-column>
    </el-table>
    <div style="margin-top:20px;">
      <el-alert type="info" :closable="false" title="已选择岗位的角色">
        <el-tag
          style="margin:0 10px 10px 0;"
          v-for="(item,i) in selection_list"
          :key="item.id"
          closable
          @close="onRemove(i)"
        >{{item.name}}</el-tag>
      </el-alert>
    </div>
    <template #footer>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import post_api from "@/http/post_api";
export default {
  data() {
    return {
      visibleDialog: false, 
      //岗位列表
      post_list: [],
      selection_list: [],
    };
  },
  props: ["single"],
  emits: ["success"],
  created() {},
  
  methods: {
    onOpen() {
      this.visibleDialog = true;
      this.loadData();
    },
    /**
     * 加载岗位数据
     */
    loadData() {
      post_api.get_posts().then((res) => {
        if (res.code == 0) {
          this.post_list = res.data;
        }
      });
    },
    /**
     * 选择更改
     */
    selectionChange(nodes) { 
      this.selection_list = nodes;
    },
    /**
     * 移除选择
     */
    onRemove(i) {
      var node = this.selection_list.splice(i, 1)[0];
      this.$refs.table.toggleRowSelection(node, false);
    },
    /**
     * 确定选择
     */
    onConfirm() {
      if (this.selection_list.length > 0) {
        var list = this.selection_list.map((x) => Object.assign({}, x));
        this.$emit("success", list);
        this.visibleDialog = false;
      }
    },
  },
};
</script>

<style   scoped>
</style>